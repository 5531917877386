import React, {useState, useEffect} from 'react'
import {
  Box,
  Button
} from '@material-ui/core'
import { AccessTime } from '@material-ui/icons'
import Layout from '../../components/Layout/layout'
import { getDatosEnTiempoReal } from '../../Utils/functions'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import LoadingBackdrop from '../../components/Utils/LoadingBackdrop'
import * as XLSX from 'xlsx'

const Reportes = () => {
  const { maquinaria } = useStoreState(state => ({
    maquinaria: state.maquinaria,
  }))
  const { getMaquinaria } = useStoreActions(actions => ({
    getMaquinaria: actions.getMaquinaria,
  }))
  const [loading, setLoading] = useState(false)
  const getReporteHorometros = async () => {
    try {
      setLoading(true)
      const codigos = maquinaria.filter(i => i.TipoMaquinaria.toUpperCase() == 'COSECHADORA').map(i => i.Codigo)
      const body = await getDatosEnTiempoReal(codigos, 'horometros')
      const worksheet = XLSX.utils.json_to_sheet(body)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Horometros')
      XLSX.writeFile(workbook, `horometros ${dayjs().format('DD-MM-YY HH-MM')}.xlsx`)
    } catch (error) {
      toast.error('Error al generar el reporte de horometros')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getMaquinaria()
  }, [])
  return <Layout>
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<AccessTime />}
        onClick={getReporteHorometros}
      >
        Generar reporte de horometros
      </Button>
    </Box>
    <LoadingBackdrop loading={loading} />
  </Layout>
}

export default Reportes