export const config = {
  velocidad: {
    params: ['baja', 'media', 'alta'],
    colors: ['#ffff00', '#00b050',  '#fe0100']
  },
  combustible: {
    params: ['baja', 'media', 'alta'],
    colors: ['#ffff00', '#00b050',  '#fe0100']
  },
  autotracker: {
    params: ['sin_at', 'con_at'],
    colors: ['#ffff00', '#00b050']
  },
  pa: {
    params: ['sin_pa', 'con_pa'],
    colors: ['#ffff00', '#00b050']
  },
  extractor: {
    params: ['correcto', 'incorrecto'],
    colors: ['#00b050',  '#fe0100']
  },
  gps: {
    params: ['autonomo', 'xfill', 'rtk', 'otros'],
    colors: ['#fe0100', '#fe9901',  '#92d14f', '#ffc000']
  },
  presion: {
    params: ['baja', 'media', 'alta'],
    colors: ['#ffff00', '#00b050',  '#fe0100']
  }
}

export const tipos = [
  {label: 'Velocidad', value: 'velocidad'},
  {label: 'Piloto automatico', value: 'pa'},
  {label: 'Consumo de combustible', value: 'combustible'},
  {label: 'Autotracker', value: 'autotracker'},
  {label: 'Extractor primario', value: 'extractor'},
  {label: 'Calidad de GPS', value: 'gps'},
  {label: 'Presión de corte base', value: 'presion'}
]

export const parametros = {
  'velocidad': [
    {label: 'Baja', value: 'baja', color: '#ffff00', checked: true},
    {label: 'Media', value: 'media', color: '#00b050', checked: true},
    {label: 'Alta', value: 'alta', color: '#fe0100', checked: true}
  ],
  'combustible':[
    {label: 'Bajo', value: 'baja', color: '#ffff00', checked: true},
    {label: 'Medio', value: 'media', color: '#00b050', checked: true},
    {label: 'Alto', value: 'alta', color: '#fe0100', checked: true}
  ],
  'autotracker': [
    {label: 'Con autotracker', value: 'con_at', color: '#00b050', checked: true},
    {label: 'Sin autotracker', value: 'sin_at', color: '#ffff00', checked: true}
  ],
  'pa': [
    {label: 'Con piloto auto.', value: 'con_pa', color: '#00b050', checked: true},
    {label: 'Sin piloto auto.', value: 'sin_pa', color: '#ffff00', checked: true}
  ],
  'extractor': [
    {label: 'Correcto', value: 'correcto', color: '#00b050', checked: true},
    {label: 'Incorrecto', value: 'incorrecto', color: '#fe0100', checked: true}
  ],
  'gps': [
    {label: 'Autonomo', value: 'autonomo', color: '#fe0100', checked: true},
    {label: 'XFill', value: 'xfill', color: '#fe9901', checked: true},
    {label: 'RTK', value: 'rtk', color: '#92d14f', checked: true},
    {label: 'Otros', value: 'otros', color: '#ffc000', checked: true}
  ],
  'presion': [
    {label: 'Baja', value: 'baja', color: '#ffff00', checked: true},
    {label: 'Media', value: 'media', color: '#00b050', checked: true},
    {label: 'Alta', value: 'alta', color: '#fe0100', checked: true}
  ],
  '': []
}

export const tipoParametros = {
  'velocidad': {
    headers: ['Baja', 'Media', 'Alta'],
    params: ['bajo', 'medio', 'alto']
  },
  'combustible': {
    headers: ['Bajo', 'Medio', 'Alto'],
    params: ['bajo', 'medio', 'alto']
  },
  'autotracker': {
    headers: ['Con AT', 'Sin AT'],
    params: ['con_at', 'sin_at']
  },
  'pa': {
    headers: ['Con PA', 'Sin PA'],
    params: ['con_pa', 'sin_pa']
  },
  'extractor': {
    headers: ['Correcto', 'Incorrecto'],
    params: ['correcto', 'incorrecto']
  },
  'gps': {
    headers: ['Autonomo', 'XFill', 'RTK','Otros'],
    params: ['autonomo', 'xfill', 'rtk', 'otros']
  },
  'presion': {
    headers: ['Baja', 'Media', 'Alta'],
    params: ['bajo', 'medio', 'alto']
  },
  '': {
    headers: [],
    params: []
  }
}

export const leyendasTipo = {
  'velocidad': [
    {label: 'Baja < 3km/h', color: '#ffff00'},
    {label: 'Media entre 3 y 4 km/h', color: '#00b050'},
    {label: 'Alta > 4km/h', color: '#fe0100'}
  ],
  'combustible':[
    {label: 'Bajo < 0.0021', color: '#ffff00'},
    {label: 'Medio entre 0.0021 y 0.0025', color: '#00b050'},
    {label: 'Alto > 0.0025', color: '#fe0100'}
  ],
  'autotracker': [
    {label: 'Sin autotracker', color: '#ffff00'},
    {label: 'Con autotracker', color: '#00b050'}
  ],
  'pa': [
    {label: 'Sin piloto auto.', color: '#ffff00'},
    {label: 'Con piloto auto.', color: '#00b050'}
  ],
  'extractor': [
    {label: 'Correcto', color: '#00b050'},
    {label: 'Incorrecto', color: '#fe0100'}
  ],
  'gps': [
    {label: 'Autonomo', color: '#fe0100'},
    {label: 'XFill', color: '#fe9901'},
    {label: 'RTK', color: '#92d14f'},
    {label: 'Otros', color: '#ffc000'}
  ],
  'presion': [
    {label: 'Baja < 30 bar', color: '#ffff00'},
    {label: 'Media entre 30 y 78 bar', color: '#00b050'},
    {label: 'Alta > 78 bar', color: '#fe0100'}
  ],
  '': []
}

export const tipoColores = {
  velocidad: {
    low: '#ffff00',
    medium: '#00b050',
    high: '#fe0100'
  },
  combustible: {
    low: '#ffff00',
    medium: '#00b050',
    high: '#fe0100'
  },
  autotracker: {
    sin_at: '#ffff00',
    con_at: '#00b050'
  },
  pa: {
    con_pa: '#00b050',
    sin_pa: '#ffff00'
  },
  extractor: {
    correcto: '#00b050',
    incorrecto: '#fe0100'
  },
  gps: {
    autonomo: '#fe0100',
    xfill: '#fe9901',
    rtk: '#92d14f',
    otros: '#ffc000'
  },
  presion: {
    low: '#ffff00',
    medium: '#00b050',
    high: '#fe0100'
  }
}