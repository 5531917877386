import React from 'react'
import Layout from '../../components/Layout/layout'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 115px)'
  }
}))

const DashboardQuicksight = () => {
  const classes = useStyles()
  return <Layout title="Reporte de operadores">
    <Box display="flex" justifyContent="center" className={classes.root}>
      <iframe
        width="100%"
        height="100%"
        src="https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/271551172366/dashboards/77a5494e-3097-407e-80b7-58975b7ee7d6?directory_alias=ingeniolaunion-qs">
      </iframe>
    </Box>
  </Layout>
}

export default DashboardQuicksight