import React, { useEffect, useState } from "react";

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from '@material-ui/core/Collapse';
import {
  ExitToApp,
  ExpandLess,
  ExpandMore,
  Brightness1,
  Room,
  Equalizer,
} from '@material-ui/icons'
import { useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { useStoreState } from "easy-peasy";
import { items } from '../../Utils/MenuItems'
import { filterOptions } from "../../Utils/auth";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => {
  let styles = {
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerStyle: { backgroundColor: "#2b3c4d" },
    drawerOption: { color: "white" },
    toolbar: {
      ...theme.mixins.toolbar,
      backgroundColor: "#202e3c",
      backgroundImage: "url(/static/logo.png)",
      backgroundRepeat: "no-repeat",
      backgroundSize: 180,
      backgroundPosition: "center",
    },
    menuCollapse: {
      paddingLeft: theme.spacing(3),
    },
    subMenuCollapse: {
      paddingLeft: theme.spacing(5),
    }
  }
  return styles;
})

const Menu = () => {
  const {permisos} = useStoreState(state => ({
    permisos: state.permisos
  }))
  const classes = useStyles()
  const history = useHistory()
  const [collapse, setCollapse] = useState(null);
  const [subCollapse, setSubCollapse] = useState(null);
  const [listItems, setListItems] = useState([])
  const navigateTo = (path) => {
    history.push(path);
  }

  const toogleCollapse = (menuId) => {
    setCollapse(menuId === collapse ? null : menuId);
  }

  const toogleSubCollapse = (subMenuId) => {
    setSubCollapse(subMenuId === subCollapse ? null : subMenuId);
  }
  useEffect(() => {
    setListItems(filterOptions(items, permisos))
  }, [permisos])

  return (
    <div className={classes.drawerStyle}>
      <div className={classes.toolbar} style={{ backgroundImage: 'url(./static/logo.png)' }} />
      <Divider />
      <List>
        {
          !permisos.includes('proveedor') && listItems.map(i =>
            i.type === 'single'
              ? <ListItem button onClick={() => navigateTo(i.to)} key={i.label}>
                  <ListItemIcon className={classes.drawerOption}>
                    {i.icon}
                  </ListItemIcon>
                  <ListItemText
                    className={classes.drawerOption}
                    primary={i.label}
                  />
                </ListItem>
              : i.type === 'primarysub'
                ? <div key={i.label}>
                  <ListItem button onClick={() => toogleCollapse(i.label)}>
                    <ListItemIcon className={classes.drawerOption}>
                      {i.icon}
                    </ListItemIcon>
                    <ListItemText
                      className={classes.drawerOption}
                      primary={i.label}
                    />
                    {
                      collapse === i.label
                        ? <ExpandLess className={classes.drawerOption} />
                        : <ExpandMore className={classes.drawerOption} />
                    }
                  </ListItem>
                  <Collapse in={collapse === i.label} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {
                        i.subitems.map(j => <div key={j.label}>
                          <ListItem
                            button
                            onClick={() => {
                              if (j.type === 'single') { navigateTo(j.to) }
                              else if (j.type === 'metodo') { j.metodo() }
                              else toogleSubCollapse(j.label)
                            }}
                            className={classes.menuCollapse}
                          >
                            <ListItemIcon className={classes.drawerOption}>
                              {j.icon}
                            </ListItemIcon>
                            <ListItemText
                              className={classes.drawerOption}
                              primaryTypographyProps={{ variant: "body2" }}
                              primary={j.label}
                            />
                            {(j.type == 'secondarysub') && (
                              subCollapse === j.label
                                ? <ExpandLess className={classes.drawerOption} />
                                : <ExpandMore className={classes.drawerOption} />
                            )}
                          </ListItem>
                          <Collapse in={subCollapse === j.label} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              {
                                j.type === 'secondarysub'
                                  ? j.subitems.map(k =>
                                    <ListItem
                                      key={k.label}
                                      button
                                      onClick={() => navigateTo(k.to)}
                                      className={classes.subMenuCollapse}
                                    >
                                      <ListItemIcon>
                                        <Brightness1
                                          style={{ fontSize: "0.7rem" }}
                                          fontSize="small"
                                          className={classes.drawerOption}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        className={classes.drawerOption}
                                        primaryTypographyProps={{ variant: "body2" }}
                                        primary={k.label}
                                      />
                                    </ListItem>)
                                  : <ListItem button onClick={() => navigateTo(i.to)} key={i.label}>
                                    <ListItemIcon className={classes.drawerOption}>
                                      {i.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                      className={classes.drawerOption}
                                      primary={i.label}
                                    />
                                  </ListItem>
                              }
                            </List>
                          </Collapse>
                        </div>)
                      }
                    </List>
                  </Collapse>
                </div>
                : undefined)
        }
        {
          permisos.includes("proveedor") && <>
            <ListItem button onClick={() => navigateTo("/mapa-ubicacion")}>
              <ListItemIcon>
                <Room className={classes.drawerOption} />
              </ListItemIcon>
              <ListItemText
                className={classes.drawerOption}
                primary={"Mapa de ubicación"}
              />
            </ListItem>
            <ListItem button onClick={() => navigateTo("/dashboard-velocidad")}>
              <ListItemIcon>
                <Equalizer className={classes.drawerOption} />
              </ListItemIcon>
              <ListItemText
                className={classes.drawerOption}
                primary={"Velocidad de maquinaria"}
              />
            </ListItem>
          </>
        }
        <ListItem button onClick={() => navigateTo("/logout")}>
          <ListItemIcon>
            <ExitToApp className={classes.drawerOption} />
          </ListItemIcon>
          <ListItemText
            className={classes.drawerOption}
            primary={"Cerrar sesión"}
          />
        </ListItem>
      </List>
    </div>
  )
}

export default Menu;
